<template>
  <div>
    <search-vertical-screen-column @submit="submitSearch" ref="searchForm" />

    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showSortModal">排序</a-button>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >
      <template slot="content" slot-scope="content,record">
        <span v-if="record.name === '丧属代办事项' || record.name === '感恩仪式告别注意事项'">
          {{ content }}
        </span>
        <span v-else class="custom-blue" @click="showContentModel(record)">
          点击查看
        </span>
      </template>
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>
      <span slot="actions" slot-scope="text, record">
        <a-space v-if="record.name !== '丧属代办事项' && record.name !== '感恩仪式告别注意事项'">
          <a @click="showEditModal(record)">编辑</a>
          <a @click="deleteColumn(record.id)" class="custom-delete">删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增栏目模态框 -->
    <new-vertical-screen-column
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑栏目模态框 -->
    <edit-vertical-screen-column
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :record="editingRecord"
      @completed="fetchData"
    />

    <show-content
      v-if="isShowContentModal"
      :visible.sync="isShowContentModal"
      :content="showContent"
    />

    <sort
      v-if="isShowSortModal"
      :visible.sync="isShowSortModal"
      :data="sortData"
      table-name="vertical_screen_columns"
      name-title="排序"
      @completed="fetchSortData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchVerticalScreenColumn from '@/views/vertical_screen_column/Search'
import {
  deleteVerticalScreenColumn, findSortVerticalScreenColumns,
  findVerticalScreenColumns,
  updateVerticalScreenColumnEffective
} from '@/api/vertical_screen_column'

export default {
  name: 'SystemPackageList',
  components: {
    Pagination,
    SearchVerticalScreenColumn,
    Sort: () => import('@/components/Sort'),
    NewVerticalScreenColumn: () => import('@/views/vertical_screen_column/New'),
    EditVerticalScreenColumn: () => import('@/views/vertical_screen_column/Edit'),
    ShowContent: () => import('@/views/vertical_screen_column/ShowContent')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowContentModal: false, // 是否展示内容弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      editingRecord: {}, // 正在编辑的记录id
      pagination: {
        total_count: 0
      },
      isShowSortModal: false,
      sortData: [],
      showContent: ''
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    columns() {
      return [
        {
          title: '栏目名称',
          dataIndex: 'name',
          width: 150
        },
        {
          title: '状态',
          width: 100,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '栏目内容',
          width: 200,
          dataIndex: 'content',
          scopedSlots: { customRender: 'content' }
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showContentModel(record) {
      this.isShowContentModal = true
      this.showContent = record.content
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },

    showSortModal() {
      findSortVerticalScreenColumns().then((res) => {
        this.sortData = res.data
        this.isShowSortModal = true
      })
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchSortData() {
      this.query = {}
      this.$refs.searchForm.clearForm()
      this.loading = true
      findVerticalScreenColumns().then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    fetchData() {
      this.loading = true
      findVerticalScreenColumns(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    changeEffective(checked, record) {
      const vm = this
      const effective = checked

      vm.editingEffectiveId = record.id
      updateVerticalScreenColumnEffective(record.id, { effective: effective })
        .then((res) => {
          vm.editingEffectiveId = 0
          if (res.code === 0) {
            record.effective = checked
          }
        })
        .catch(() => {
          vm.editingEffectiveId = 0
        })
    },

    deleteColumn(recordId) {
      const thisForm = this
      this.$confirm({
        title: '确定进行删除吗？删除后无法恢复',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteVerticalScreenColumn(recordId).then(() => {
            thisForm.fetchData()
          })
        }
      })
    }
  }
}
</script>
