import request from '@/utils/request'

// 查询栏目列表
export function findVerticalScreenColumns(params) {
  return request({
    url: `/vertical_screen_columns`,
    method: 'get',
    params: params
  })
}

// 查询栏目排序列表
export function findSortVerticalScreenColumns() {
  return request({
    url: `/vertical_screen_columns/sort`,
    method: 'get'
  })
}

// 更新栏目
export function updateVerticalScreenColumn(id, data) {
  return request({
    url: `/vertical_screen_columns/${id}`,
    method: 'put',
    data
  })
}

// 创建栏目
export function createVerticalScreenColumn(data) {
  return request({
    url: `/vertical_screen_columns`,
    method: 'post',
    data
  })
}

// 删除栏目
export function deleteVerticalScreenColumn(id) {
  return request({
    url: `/vertical_screen_columns/${id}`,
    method: 'delete'
  })
}

// 更新栏目有效性
export function updateVerticalScreenColumnEffective(id, data) {
  return request({
    url: `/vertical_screen_columns/${id}/effective`,
    method: 'patch',
    data
  })
}
